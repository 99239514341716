import '@/styles/main.scss'

document.addEventListener('DOMContentLoaded', function() {
  function toggleMenu(body) {
    body.getAttribute('data-menu-state') === 'closed'
        ? body.setAttribute('data-menu-state', 'open')
        : body.setAttribute('data-menu-state', 'closed');
  }

  const menuToggleElements = document.querySelectorAll('.c-menu-icon__wrapper');

  menuToggleElements.forEach(element => {
    element.addEventListener('click', function() {
      toggleMenu(document.body);
    });
  });

  document.querySelectorAll('.c-menu-icon__wrapper').forEach(function(element) {
    element.addEventListener('click', function(e) {
      e.stopPropagation();
      e.preventDefault();
      const svg = element.querySelector('svg');
      if (svg.classList.contains('c-menu-icon--open')) {
        svg.classList.remove('c-menu-icon--open');
      } else {
        svg.classList.add('c-menu-icon--open');
      }
    });
  });

  document.querySelectorAll('.c-menu-icon__wrapper').forEach(function(element) {
    element.addEventListener('click', function() {
      document.querySelectorAll('.c-menu').forEach(function(menuElement) {
        menuElement.classList.toggle('c-menu--active');
      });
    });
  });

  if (window.location.pathname === '/' && window.innerHeight >= 827) {
    document.body.style.overflow = 'hidden';
  }
});
